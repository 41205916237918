.bg-user {
    background-image: url("../../../assests/Img/20210204_a547.jpg");
    background-position: center;
    background-size: cover;
    height: 100vh;
  }


.us-h1{

    padding: 20px 0px;
    color: #0072bb;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    text-transform: uppercase;
}
.head-use{
    padding: 20px 0px;
}


.cl1{
    gap: 10px;
display: flex;
    flex-direction: column-reverse;
    align-items: center;
}
.payment-status {
    display: flex;
    justify-content: space-around;
}

@media only screen and (max-width:768px) {

    .payment-status {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }



}



.view-btn-2{
    padding: 8px 25px;
    color: white;
    background: #0072bb;
    border-radius: 8px;
    border: none;
}

@media only screen and (max-width:370px) {

.pay-status{

    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    gap: 1rem;
}
}
.cl1>span{
    font-size: 15px;
}

.status-btn-1{

    padding: 8px 25px;
    color: white;
    background: #0072bb;
    border-radius: 8px;
    border: none;
}


.secb1{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0px;
}

.secb2{
    width: 90%;
}



.com-head>h1{
font-size: 25px;
    text-align: center;
    font-weight: 100;
}

.module-a15 {
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    padding: 28px;
    background: #a9a9a94a;
    align-items: center;
}
.modulea-22 {
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    /* text-decoration-line: line-through; */
}
.svg-tick1>svg{
    color: #0072bb;

}

.user-profile-n{
text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 1rem;
}

/* Add this CSS to your User.css file or a separate stylesheet */
/* User.css */
.custom-select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    outline: none;
  }
  
  .select-container {

    text-align: center;
    display: flex;
    justify-content: flex-end;
    padding: 0px 20px;
}
  /* Style for the options within the select */
  .custom-select option {
    font-size: 16px;
    background-color: #fff;
    color: #333;
  }
  