.bg-hh{
    background-image: url('../../../assests/Img/20210204_a547.jpg');
    background-position: center;
    background-size: cover;
    height: 100vh;
    background-repeat: repeat;
    }
    .main-div-h-d{
        display: flex;
    }
    
    .img-home-g{
        width: 450px;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    }
    .img-home-g1{
        width: 450px;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    }
    .img-home-g2{
        cursor: pointer;
        width: 340px;
        height: auto;
        object-fit: cover;
        border-radius: 15px;
    }
    .home-p-gg{
        padding-bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        /* margin: 30px 0px; */
        flex-wrap: wrap;
        /* margin: 30px 0px; */
    }
    .log-2{
    
        display: flex;
        flex-direction: column;
        width: fit-content;
        align-items: center;
    }
    .home-logo{
    
        width: 200px;
    }
    .top-ii{
    
        margin-top: 40px;
    }
    .home-side-bar-data{
        display: flex;
        width: 100%;
    }
    .update-1>h1{
    
        font-size: 16px;
        /* position: relative; */
        padding: 1rem 1rem;
        margin-bottom: 1rem;
        border-radius: 0.25rem;
        border: 1px solid transparent;
        color: #d41d1f;
        background-color: #fdf7f7;
    border-color: #fca8a8;
    cursor: pointer;
    }
    .data-div-h{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0px;
    }
    
    .data-container{
    width: 90%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        /* align-items: center; */
  
    
    }
    @media only screen and (max-width:600px) {
    
        .data-container{
       
       
            
            }
            
        .modal-open-b1{
    
            display: flex;
            justify-content:center !important;
        }
        .home-side-bar-data {
            display:unset !important;
            width: 100%;
        }
    }
    
    .h1-courses>h1{
        padding: 20px 0px;
        color: #0072bb;
        text-align: center;
        font-weight: 400;
        font-size: 24px;
        text-transform: uppercase;
    }
    .course-home-cards{
    padding: 15px 0px;
    }
    .card-h-certifi>h1{
        text-align: center;
    
        font-size: 15px;
        padding: 10px 0px;
       
    }
    .card-flex-wrap{
    
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        
    }
    .bodd-d{
         justify-content: center;
        align-items: center;
    
        display: flex;
       
    
      }
      
      .progress {
        background:black;
        justify-content: flex-start;
        border-radius: 100px;
        align-items: center;
        position: relative;
        padding: 0 5px;
        display: flex;
        height: 7px;
        width: 200px;
      }
      
      .progress-value {
        animation: load 3s normal forwards;
        box-shadow: 0 10px 40px -10px #fff;
        border-radius: 100px;
        background: #0072bb;
        height: 5px;
        width: 0;
        transform: translateX(-4px);
      }
      
      @keyframes load {
        0% { width: 0; }
        100% { width: 68%; }
      }
      .ww{
        width: 300px;
        border-radius: 15px ;
        cursor: pointer;
      }
    
      .card-head-course{
        gap: 2rem;
    padding: 20px 0px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
        }
        .percentage-course-com{
            color: #0072bb;
            font-size: 12px;
            font-weight: 700;
            text-align: center;
            padding: 10px 0px;
        }
        .view-btn-hom{
    
            font-family: "Roboto", Sans-serif;
            font-size: 12px;
            font-weight: 500;
            color: #ffffff;
            background-color: #0072bb;
            box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 50%);
            border-radius: 4px 4px 4px 4px;
            padding: 15px 15px ;
            transition: 300ms;
        }
        .card-head-data-j{
            display: flex;
            
            flex-direction: column;
            align-items: center;
            /* gap: 1rem; */
        }
        
    
    
        @media only screen and (max-width: 360px){
    
    
            .bg-hh{
                background-image: url('../../../assests/Img/20210204_a547.jpg');

                background-position: center;
                background-size: cover;
                height: 150vh;
                background-repeat: repeat;
                }
        }
    
    
    
    
    
        .modal-open-b1{
         padding: 10px 0px;
            display: flex;
            justify-content: flex-end;
        }
    .add-course-btn{
    
        padding: 10px 15px;
        border-radius:8px;
        color: white;
        background-color: #0072bb;
    }
  
    
   
    .ReactModal__Overlay--after-open {
        background-color: rgb(0 0 0 / 75%) !important;
    }
    .change-btn-dis {
        padding: 7px 16px;
        border: 1px solid;
        color: white;
        background: #0072bb;
        border-radius: 8px;
    }
    .fotNOM {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        min-width: unset !important;
        max-width:  unset !important;
        height: 48px;
        border: dashed 2px #0658c2;
        padding: 8px 16px 8px 8px;
        border-radius: 5px;
        cursor: pointer;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
    }
    .ReactModal__Content {
        padding: 0px !important;
        /* inset: 73px 317px !important; */
        /* height: 70vh; */
        height: fit-content;
        margin-top: 70px;
      
      }
      @media only screen and (max-width: 1050px) {
        .ReactModal__Content {
            /* inset: 68px 107px !important; */
            /* inset: 147px 70px !important; */
        }
      
      }
      @media only screen and (max-width: 600px) {
        
      
        .modal-content-h{
  
         width: 300px !important;
        }
        }
        .modal-content-h{
        
         width: 500px;
            /* height: 70vh; */
            height: -moz-fit-content;
            height: fit-content;
            margin-top: 70px;
            position: absolute;
        
            border: 1px solid rgb(204, 204, 204);
            background: rgb(255, 255, 255);
            overflow: auto;
            border-radius: 4px;
            outline: none;
            padding: 20px;
        }
        .header-modal-a1{
            display: flex;
            align-items: center;
         padding: 15px;
            justify-content: space-between;
        }
.home-ah1{

    
    text-align: center;
    text-transform: uppercase;
    font-size: 15px;
    
}
.upload-options{
  
    display: flex;
    gap: 1rem;
    padding: 10px 0px;
}
.upload-options>input{
    border: 1px solid;
    padding: 11px;
    /* cursor: pointer; */

}
.latest-up-a1{

}
.input-a1{
border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    display: inline-block;
    margin: 8px 0;
    padding: 12px 20px;
    width: 100%;
}

.ReactModal__Overlay {
    display: flex;
    justify-content: center;
}
.check-box-post-btn{

    display: flex;
    justify-content: center;

}

@media only screen and (max-width: 500px) {
  
    .check-box-post-btn {
        gap: 20px;
      
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
  
    
    }

.change-course-img{
    padding: 15px 0px;
    display: flex;
    justify-content: center;
}
.form-data-a1{
    padding: 15px;
}
.delete-a1{

    font-family: "Roboto", Sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    background-color: #0072bb;

    /* border-radius: 4px 4px 4px 4px; */
    padding: 14px 30px;
    transition: 300ms;
}
.edit-a1{

    font-family: "Roboto", Sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    background-color: #0072bb;

    border-radius: 15px 0px 0px 15px;
    padding: 14px 30px;
    transition: 300ms;
}
.course-edit-del{

    display: flex;
  
    gap: 1px;

    flex-wrap: wrap;
    justify-content: center;
}
.module-btn1{

    font-family: "Roboto", Sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    background-color: #0072bb;

    border-radius: 0px 15px 15px 0px;
    padding: 14px 30px;
    transition: 300ms;
}
    .modules-btn-div{
       padding: 20px 0px;
    }